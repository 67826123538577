exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-guides-and-hosts-tsx": () => import("./../../../src/pages/guides-and-hosts.tsx" /* webpackChunkName: "component---src-pages-guides-and-hosts-tsx" */),
  "component---src-pages-hotels-strapi-hotels-collection-slug-tsx": () => import("./../../../src/pages/hotels/{StrapiHotelsCollection.slug}.tsx" /* webpackChunkName: "component---src-pages-hotels-strapi-hotels-collection-slug-tsx" */),
  "component---src-pages-hotels-tsx": () => import("./../../../src/pages/hotels.tsx" /* webpackChunkName: "component---src-pages-hotels-tsx" */),
  "component---src-pages-hum-sl-team-tsx": () => import("./../../../src/pages/hum-sl-team.tsx" /* webpackChunkName: "component---src-pages-hum-sl-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mice-events-that-we-do-tsx": () => import("./../../../src/pages/mice-events-that-we-do.tsx" /* webpackChunkName: "component---src-pages-mice-events-that-we-do-tsx" */),
  "component---src-pages-mice-experiences-tsx": () => import("./../../../src/pages/mice-experiences.tsx" /* webpackChunkName: "component---src-pages-mice-experiences-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-past-humming-bird-events-tsx": () => import("./../../../src/pages/past-humming-bird-events.tsx" /* webpackChunkName: "component---src-pages-past-humming-bird-events-tsx" */),
  "component---src-pages-sustainability-and-philanthropy-tsx": () => import("./../../../src/pages/sustainability-and-philanthropy.tsx" /* webpackChunkName: "component---src-pages-sustainability-and-philanthropy-tsx" */),
  "component---src-pages-transport-tsx": () => import("./../../../src/pages/transport.tsx" /* webpackChunkName: "component---src-pages-transport-tsx" */),
  "component---src-pages-why-hummingbird-events-tsx": () => import("./../../../src/pages/why-hummingbird-events.tsx" /* webpackChunkName: "component---src-pages-why-hummingbird-events-tsx" */)
}

